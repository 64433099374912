import React from "react";

//components
import {
	Grid,
	Col,
	Card,
	CloudinaryImage,
	Text,
} from "@website-builder/ui/shared/elements";

//styles
import {
	MentorCardListWrapper,
	MentorCardsContainer,
	MentorItemImageWrapper,
	MentorItemContentWrapper,
	MentorItemCompanyLogoWrapper,
} from "./styles";

const MentorCardList = ({
	mentorCardItems,
	title,
	description,
	removeTopSpacing,
}) => {
	// defines the count of mentor
	const noOfMentorToRender = mentorCardItems?.length;
	// defines width of the card based on the mentor count
	const isMentorCountLessThan3 = noOfMentorToRender <= 3;

	const CardItem = ({ name, position, companyLogo, profileImage }) => {
		return (
			<Card className="mentor-card-item">
				<MentorItemImageWrapper>
					<CloudinaryImage
						className="mentor-profile-image"
						url={profileImage?.image?.image}
						alt={profileImage?.image?.altTag}
						quality="auto"
						lazyload
						fetchFormat="auto"
						dynamic
					/>
				</MentorItemImageWrapper>
				<MentorItemContentWrapper>
					<div className="mentor-item-text-wrapper">
						<Text
							color={{
								base: "primary",
								color: "neutral-nightshade",
								shade: "800",
							}}
							variant="headline_6"
							tag="p"
						>
							{name}
						</Text>
						{position ? (
							<Text
								color={{
									base: "primary",
									color: "neutral-nightshade",
									shade: "600",
								}}
								variant="paragraph_M"
							>
								{position}
							</Text>
						) : null}
					</div>
					{companyLogo?.image?.image ? (
						<MentorItemCompanyLogoWrapper>
							<CloudinaryImage
								className="mentor-company-logo"
								url={companyLogo?.image?.image}
								alt={companyLogo?.image?.altTag}
								quality="auto"
								lazyload
								fetchFormat="auto"
								dynamic
							/>
						</MentorItemCompanyLogoWrapper>
					) : null}
				</MentorItemContentWrapper>
			</Card>
		);
	};
	return (
		<MentorCardListWrapper
			isMentorCountLessThan3={isMentorCountLessThan3}
			removeTopSpacing={removeTopSpacing}
			className="mentor-card-list outer-margin-wrap"
		>
			{title ? (
				<Text className="title" variant="headline_3" tag="h2">
					{title}
				</Text>
			) : null}
			{description ? (
				<Text
					className="description rich-text-wrapper"
					tag="div"
					dangerouslySetInnerHTML={{ __html: description }}
				/>
			) : null}
			<Grid>
				<Col span={12} mdSpan={6}>
					<MentorCardsContainer isMentorCountLessThan3={isMentorCountLessThan3}>
						{mentorCardItems.map((mentor, index) => (
							<CardItem {...mentor} key={mentor?.name + index} />
						))}
					</MentorCardsContainer>
				</Col>
			</Grid>
		</MentorCardListWrapper>
	);
};

export default MentorCardList;
