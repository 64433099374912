import styled from "styled-components";
import {
	DESKTOP_M_BP,
	TABLET_M_BP,
} from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import { getAnchorStyles } from "@website-builder/utilities/utils/DesignUtils.js";

export const MentorItemImageWrapper = styled.div`
	overflow: hidden;
	border-top-left-radius: 0.5rem;
	border-top-right-radius: 0.5rem;
	height: 217px;
`;

export const MentorItemContentWrapper = styled.div`
	padding: 1.5rem;
	display: flex;
	gap: 1rem;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.mentor-item-text-wrapper {
		display: flex;
		gap: 0.5rem;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.mentor-item-text-wrapper p,
	h6 {
		text-align: center;
	}
`;
export const MentorItemCompanyLogoWrapper = styled.div`
	height: 32px;
	width: 80px;
`;
export const MentorCardsContainer = styled.div`
	display: flex;
	gap: 1.5rem;
	flex-wrap: wrap;
	justify-content: center;
`;

export const MentorCardListWrapper = styled.div`
	padding: ${({ removeTopSpacing }) =>
		removeTopSpacing ? "0 0 80px" : "80px 0"};
	.title {
		margin: 0 96px 24px;
		text-align: center;
		font-weight: 500;
	}
	.description {
		margin: 24px 96px 58px;
		text-align: center;
	}
	.mentor-card-item {
		padding: 0;
		max-height: 372px;
		max-width: ${(props) => (props.isMentorCountLessThan3 ? `400px` : `280px`)};
		min-width: 280px;
		display: flex;
		flex-direction: column;
		z-index: 1;
		flex: 1;
	}
	.mentor-profile-image {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
	.mentor-company-logo {
		object-fit: contain;
		width: 100%;
		height: 100%;
	}
	.rich-text-wrapper {
		${getAnchorStyles()}
	}
	@media only screen and (max-width: ${DESKTOP_M_BP}px) {
		.mentor-card-item {
			max-width: 292px;
		}
		.title {
			margin: 0 24px 12px;
		}
		.description {
			margin: 12px 24px;
		}
	}
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		padding: 40px 0;
	}
`;
